import { orderTakingActionTypes } from "../constants/actionTypes"

const initialState = {};

export const orderTakingReducer = (state = initialState, { type, payload = {} }) => {
	switch (type) {
		case orderTakingActionTypes.ORDER_TAKING_DATA_LOAD: {
			return {
				...state,
				...payload.data.data
			}
		}

		case orderTakingActionTypes.SAVE_ORDER_TAKING: {
			return {
				...state,
				...payload.data.data
			}
		}

		default: return state
	}
}